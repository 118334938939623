/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
<template>
    <div :class="classes">
        <slot name="prepend" />
        <GridHeaderSettings>
            <template #actions>
                <slot name="actions" />
            </template>
            <template #configuration>
                <button
                    v-if="product_grid_url"
                    aria-label="Fab button"
                    type="button"
                    class="button buttton_save_grid"
                    data-cy="grid-table-view"
                    @click="copyURL">
                    СОХРАНИТЬ СЕТКУ В URL
                </button>

                <template v-if="layoutActivators.length > 1">
                    <Component
                        v-for="activator in layoutActivators"
                        :key="activator.key"
                        :is="activator.component"
                        :selected="layout === activator.key"
                        :data-cy="activator.dataCy"
                        @active="onLayoutActivate" />
                </template>
                <Fab
                    :theme="theme.SECONDARY"
                    @click.native="onShowModal">
                    <template #icon="{ color }">
                        <IconSettings :fill-color="color" />
                    </template>
                </Fab>
                <slot name="configuration" />
            </template>
        </GridHeaderSettings>
        <GridSettingsModalForm
            v-if="isSettingsModal"
            :layout-configs="layoutConfigs"
            @close="onCloseModal"
            @apply="onApplySettings" />
        <slot name="append" />
    </div>
</template>

<script>
import {
    GRID_LAYOUT,
} from '@Core/defaults/grid';
import {
    THEME,
} from '@Core/defaults/theme';
import {
    getParams,
} from '@Core/models/mappers/gridDataMapper';

export default {
    name: 'GridHeader',
    props: {
        /**
         * Configuration of layouts
         */
        layoutConfigs: {
            type: Object,
            required: true,
        },
        /**
         * The available layouts
         */
        layoutActivators: {
            type: Array,
            required: true,
        },
        /**
         * Current selected grid layout
         */
        layout: {
            type: String,
            default: GRID_LAYOUT.TABLE,
        },
    },
    data() {
        let product_grid_url = false;
        if (this.$route.name === 'catalog-products') {
            product_grid_url = true;
        }
        return {
            product_grid_url: product_grid_url,
            isSettingsModal: false,
        };
    },
    computed: {
        classes() {
            return [
                'grid-header',
            ];
        },
        theme() {
            return THEME;
        },
    },
    methods: {
        onLayoutActivate(layout) {
            this.$emit('layout-change', layout);
        },
        onShowModal() {
            this.isSettingsModal = true;
        },
        onCloseModal() {
            this.isSettingsModal = false;
        },
        onApplySettings(payload) {
            this.isSettingsModal = false;

            this.$emit('apply-settings', payload);
        },

        copyURL() {
            const defaultColumns = 'esa_template:ru_RU, esa_sku, bsa_ge_name:ru_RU';
            const params = getParams({
                $route: this.$route,
                $cookies: this.$gridCookies,
                layout: this.layout,
                defaultColumns: defaultColumns,
            });

            if (this.$route.query.advancedFilter !== undefined && this.$route.query.advancedFilter !== '') {
                var advancedFilter = this.$route.query.advancedFilter; 
            }

            // console.log('advancedFilter', advancedFilter);

            if (params.columns !== undefined && params.columns !== '') {
                let url = '/catalog/products?' + 'columns=' + params.columns
                if (advancedFilter !== undefined) {
                    url = url + '&advancedFilter=' + advancedFilter;
                }
                this.$router.push(url);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    .grid-header {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        box-sizing: border-box;
        background-color: $WHITE;
    }
    .buttton_save_grid {
        font: 700 11px/15px "Inter UI", sans-serif;
        border: 0;
        color: #5C6064;
        background-color: $WHITE;
        padding: 10px 10px 7px 10px;
        transition: all 0.3s;
    }
    .buttton_save_grid:hover {
        -webkit-box-shadow: 0 5px 8px rgba(0, 188, 135, 0.28), 0 3px 22px rgba(0, 188, 135, 0.2), 0 10px 17px rgba(0, 188, 135, 0.22);
        box-shadow: 0 5px 8px rgba(0, 188, 135, 0.28), 0 3px 22px rgba(0, 188, 135, 0.2), 0 10px 17px rgba(0, 188, 135, 0.22);
        cursor: pointer;
    }
</style>
